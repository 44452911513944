import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getUserAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/center/get_user_account', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUserAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/center/edit_user_account/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageUserAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/center/upload_image_user_account/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resendVerifyUserAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/center/resend_verify_user_account/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
