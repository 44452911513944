<template>
  <div>
    <b-alert
      v-if="userData.is_verified === '0' && userData.role === 'member'"
      show
      variant="warning"
      class="mb-50"
    >
      <div class="alert-body">
        {{ $t('Your email is not confirmed. Please check your inbox') }}
      </div>
    </b-alert>
    <b-alert
      show
      variant="primary"
      class="mb-50"
    >
      <div class="alert-body">
        {{ $t('Contact us') }}
        <a href="mailto:info@adxbot.com">info@adxbot.com</a>
      </div>
    </b-alert>
    <b-tabs
      pills
      nav-wrapper-class="col-12"
      content-class="col-12 col-md-12 mt-1 mt-md-0"
    >

      <!-- general tab -->
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('General') }}</span>
        </template>

        <user-account-general
          v-if="options.general"
          :general-data="options.general"
          @update-user-account="updateUserAccount"
        />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('Change Password') }}</span>
        </template>

        <user-account-password />
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('Information') }}</span>
        </template>

        <user-account-information
          v-if="options.info"
          :information-data="options.info"
          @update-user-account="updateUserAccount"
        />
      </b-tab>

      <!-- social links -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LinkIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('Social') }}</span>
        </template>

        <user-account-social
          v-if="options.social"
          :social-data="options.social"
          @update-user-account="updateUserAccount"
        />
      </b-tab>

      <!-- notification -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="BellIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('Notifications') }}</span>
        </template>

        <user-account-notification
          v-if="options.notification"
          :notification-data="options.notification"
          @update-user-account="updateUserAccount"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BAlert,
} from 'bootstrap-vue'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import UserAccountGeneral from './UserAccountGeneral.vue'
import UserAccountPassword from './UserAccountPassword.vue'
import UserAccountInformation from './UserAccountInformation.vue'
import UserAccountSocial from './UserAccountSocial.vue'
import UserAccountNotification from './UserAccountNotification.vue'
import userAccountStoreModule from './userAccountStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    BAlert,
    UserAccountGeneral,
    UserAccountPassword,
    UserAccountInformation,
    UserAccountSocial,
    UserAccountNotification,
  },
  data() {
    return {
      options: {},
      userData: JSON.parse(localStorage.getItem('adx-user-data')),
    }
  },
  mounted() {
    store
      .dispatch('user-account-store/getUserAccount', { id: this.userData.id })
      .then(response => {
        const okMsg = this.$i18n.t('Process on successfully')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'bottom-right',
        })
        this.options = response.data
      })
      .catch(error => {
        const { response, message } = error
        if (response) {
          const errorMsg = this.$i18n.t(response?.data.error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMsg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        } else if (message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        }
      })
  },
  methods: {
    updateUserAccount(data) {
      this.options = data
    },
  },
  setup() {
    const USER_ACCOUNT_STORE_MODULE_NAME = 'user-account-store'

    // Register module
    if (!store.hasModule(USER_ACCOUNT_STORE_MODULE_NAME)) {
      store.registerModule(USER_ACCOUNT_STORE_MODULE_NAME, userAccountStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_ACCOUNT_STORE_MODULE_NAME)) store.unregisterModule(USER_ACCOUNT_STORE_MODULE_NAME)
    })

    return {}
  },
}
</script>
